import Vue from 'vue'

window.Vue = Vue

import helpers from './vue/helpers.js'
const plugin = {
  install() {
    Vue.helpers = helpers
    Vue.prototype.$helpers = helpers
  }
}
Vue.use(plugin)


Vue.filter('nl2br', function(value){ return value.split("\n").join('<br>') })


function formatMoney(n, c, d, t) {
    var c = isNaN(c = Math.abs(c)) ? 2 : c,
      d = d == undefined ? "." : d,
      t = t == undefined ? "," : t,
      s = n < 0 ? "-" : "",
      i = String(parseInt(n = Math.abs(Number(n) || 0).toFixed(c))),
      j = (j = i.length) > 3 ? j % 3 : 0;
  
    return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
};
Vue.filter('money', function(value){ return formatMoney(value) })


Vue.filter('mysql_shortdate', function(value){ 
  if (!value) 
    return '';

  var regex = /(\d{4})-(\d{1,2})-(\d{1,2})/;
  var results = value.match(regex);
  var y = results[1];
  var m = results[2];
  var d = results[3];
  return [d, m, y.substring(2)].join("/");
});

Vue.filter('shortdate', function(value){ 
  if ((!value) || isNaN(value.getTime()))
    return '';

  return `${('00' + value.getDate()).substr(-2)}-${('00' + (value.getMonth() + 1)).substr(-2)}-${(value.getFullYear()+"").substr(-2)}`
});

Vue.filter('date', function(value){ 
  if ((!value) || isNaN(value.getTime()))
    return '';

  return `${('00' + value.getDate()).substr(-2)}-${('00' + (value.getMonth() + 1)).substr(-2)}-${(value.getFullYear()+"")}`
});

Vue.filter('slashdate', function(value){ 
  if ((!value) || isNaN(value.getTime()))
    return '';

  return `${('00' + value.getDate()).substr(-2)}/${('00' + (value.getMonth() + 1)).substr(-2)}/${(value.getFullYear()+"")}`
});


Vue.filter('array_join', function(value, delimiter = ', '){
  var array = value;
  if (typeof value === 'object')
    array = Object.values(value);
  return array.join(delimiter);
});


Vue.filter('array_column', function(arr, col){
  return arr.map(a => {
    return a[col];
  });
});