export default {

    dateToString(date, format = 'dd-mm-yyyy') {
        if (!date instanceof Date)
            return ''

        if (isNaN(date.getTime()))
            return ''

        const year = String(date.getFullYear())
        const month = String(date.getMonth() + 1)
        const dateNumber = String(date.getDate())

        const replacements = [
            { pattern: /y{4}/i, value: year },
            { pattern: /y{2}/i, value: year.substr(-2) },
            { pattern: /y/i, value: year },
            { pattern: /m{2}/i, value: ('00' + month).substr(-2) },
            { pattern: /m/i, value: month },
            { pattern: /d{2}/i, value: ('00' + dateNumber).substr(-2) },
            { pattern: /d/i, value: dateNumber },
        ]

        return replacements.reduce((ac, { pattern, value }) => {
            return ac.replace(pattern, value)
        }, format)

        // return `${('00' + date.getDate()).substr(-2)}-${('00' + (date.getMonth() + 1)).substr(-2)}-${date.getFullYear()}`
    },

    stringToDate(str) {
        const text = String(str)
        const pos = text.indexOf('T')
        const dateStr = text.substring(0, pos === -1 ? text.length : pos)
        const timeStr = text.substring(pos === -1 ? text.length : pos+1)


        const dateParts = dateStr.split(/[\D]+/)
        if (dateParts[0] == 0 && dateParts[1] == 0 && dateParts[2] == 0)
            return new Date('')


        const timeParts = timeStr.split(/[\D]+/)
        const hours = timeParts[0] || 0
        const minutes = timeParts[1] || 0
        const seconds = timeParts[2] || 0

        if (dateParts.length == 3) {
            if (dateParts[0].length == 4) {
                return new Date(
                    dateParts[0], 
                    dateParts[1] -1, 
                    dateParts[2],
                    hours,
                    minutes,
                    seconds
                )       
            }
            else if (dateParts[2].length == 4) {
                return new Date(
                    dateParts[2], 
                    dateParts[1] -1, 
                    dateParts[0],
                    hours,
                    minutes,
                    seconds
                )       
            }
        }
    },

    validateDate(str) {
        const text = String(str)
        const pos = text.indexOf('T')
        const dateStr = text.substring(0, pos === -1 ? text.length : pos)
        const timeStr = text.substring(pos === -1 ? text.length : pos+1)


        const dateParts = dateStr.split(/[\D]+/)
        if (dateParts[0] == 0 || dateParts[1] == 0 || dateParts[2] == 0)
            return false


        // const timeParts = timeStr.split(/[\D]+/)
        // const hours = timeParts[0] || 0
        // const minutes = timeParts[1] || 0
        // const seconds = timeParts[2] || 0

        if (dateParts.length == 3) {
            if (dateParts[0].length == 4) {
                const d = dateParts[2]
                const m = dateParts[1]
                const y = dateParts[0]
                const date = new Date(y,m-1,d, 0,0,0,0)
                return (d == date.getDate() && m - 1 == date.getMonth() && y == date.getFullYear())
            }
            else if (dateParts[2].length == 4) {
                const d = dateParts[0]
                const m = dateParts[1]
                const y = dateParts[2]
                const date = new Date(y, m - 1, d, 0, 0, 0, 0)
                return (d == date.getDate() && m - 1 == date.getMonth() && y == date.getFullYear())
            }
        }

        return false
    },

    calculateDays(fromDate, toDate) {
        if ((!fromDate) || (!toDate))
            return 
        
        const msecs = toDate.getTime() - fromDate.getTime()
        const secs = msecs / 1000
        const days = secs / 86400

        return days
    },

    // TIME 


    splitTime(date) {
        if (!date instanceof Date)
            return {}

        if (isNaN(date.getTime()))
            return {}

        const h24 = date.getHours()
        const h = h24 % 12
        const h12 = h != 0 ? h : 12
        const m = date.getMinutes()
        const s = date.getSeconds()
        const a = h == h24 ? 'am' : 'pm'
        
        
        return {h24, h12, m, s, a}
    },

}