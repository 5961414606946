import 'expose-loader?exposes=moment!moment'
import 'expose-loader?exposes=$,jQuery!jquery'
import 'jquery-ui'
import 'jquery-ui/ui/widgets/datepicker'
import 'jquery-date-range-picker/src/jquery.daterangepicker'
// import 'expose-loader?plupload!plupload'
// import 'expose-loader?exposes=Decimal!decimal.js'
import 'expose-loader?exposes=SignaturePad!../node_modules/signature_pad/dist/signature_pad.js'
import 'expose-loader?exposes=axios!axios'
import 'script-loader!./common.js'
import 'script-loader!./controller.js'
import 'script-loader!./dom.js'
import 'script-loader!./RectPacker.js'

import './vue.js'

import '../css/reset.css'
import '../css/app.scss'
import 'font-awesome/css/font-awesome.css'
import 'jquery-date-range-picker/src/daterangepicker.scss'
import '../css/components.css'
import '../css/common_1.css'
import '../css/icons.css'
import '../css/dom.css'
import '../css/common.scss'

import './alpine_dom.js'

import Decimal from 'decimal.js'
window.Decimal = Decimal

import Alpine from 'alpinejs'
window.Alpine = Alpine
Alpine.start()
