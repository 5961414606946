import Alpine from "alpinejs"


document.addEventListener('alpine:init', () => {
    Alpine.data('tag', (initialState = {options: [], value: ''}) => ({
        ...initialState,
        get isMultiple() { return !!this.$root.getAttribute('multiple') },
        get isRequired() { return !!this.$root.getAttribute('required') },
        get selections(){ return this.value.split(';') },
        get items(){ return this.options.map(option => ({value:option.id,option,selected:this.selections.includes(option.id)})) },
        select(id) {
            let newValue = ''

            if (this.isMultiple) {
                let selections = this.selections,
                    index = selections.indexOf(id+'')

                if (index === -1)
                    selections.push(id)
                else
                    selections.splice(index, 1)

                newValue = selections.join(';')
            }
            else {
                newValue = (
                    this.selections == id ? '' : id
                )
            }

            if (this.isRequired && newValue.trim() == '') {
                newValue = this.value
            }

            
            
            const changed = (this.value != newValue)

            this.value = newValue

            if (changed) {
                const element = this.$root.querySelector('input')

                if (element) {
                    this.$nextTick(() => {
                        const event = new Event('change')
                        element.dispatchEvent(event)
                    })
                }
            }

        }        
    }))


    Alpine.data('combobox', (initialState = {options: {}, value: ''}) => ({
        ...initialState,
        filter:'',
        
        init() {
            this.$watch('expanding', v => {    
                if (v) {
                    const filterEl = this.$refs.filter
                    if (filterEl) {
                        filterEl.focus()
                    }
                }
            })
        },

        select(value) { 
            const changed = this.value != value

            this.value = value 

            if (changed) {
                const element = this.$refs.hiddenInput

                if (element) {
                    this.$nextTick(() => {
                        const event = new Event('change')
                        element.dispatchEvent(event)
                    })
                }
            }

        },
        selectFirstItem() {
            const firstItem = this.items[0]
            if (firstItem) {
                this.select(firstItem.value)
                this.expanding = false
            }
        },

        get items(){ 
            const filter = this.filter
            return this.options
                .map(option => ({value:option.id, option, selected:this.value == option.id}))
                .filter(item => (!filter) || item.option.title.toLowerCase().indexOf(filter.toLowerCase()) > -1)
        },

        get selectedItem() { return this.items.filter(f => f.selected)[0] },

        get placeholder() { return this.$refs.hiddenInput.getAttribute('placeholder') },

        get isDisabled() { return this.$root.getAttribute('disabled') == 'disabled' },


        expanding: false,
        setExpanding(v) {
            if (this.isDisabled)
                return

            this.expanding = v
        },
        expand() { this.setExpanding(true) },
        collapse() { this.setExpanding(false) },
        toggle() { this.setExpanding(!this.expanding) },

        get dropStyle() {
            const 
                controlEl = this.$refs.control,
                controlRect = controlEl.getBoundingClientRect(),
                x = controlEl.offsetLeft,
                y = controlEl.offsetTop + controlEl.offsetHeight,
                h = window.innerHeight - (controlRect.y + controlEl.offsetHeight) - 100
                // h = window.innerHeight - controlEl.offsetTop

            return {
                top: y+'px',
                bottom: 'auto',
                left: x+'px',
                maxHeight: h+'px',
                overflow: 'hidden'
            }
        }
    }))

})